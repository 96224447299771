import React from 'react';

import { Grid, List, ListItem, CssBaseline, ListItemIcon, Link, ListItemText, CircularProgress, Typography, Button, TextField, Container, withStyles, Card, CardContent } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import NumbersIcon from '@mui/icons-material/Numbers';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: 10,
		marginBottom: 10,
	},
	appbar: theme.mixins.toolbar,
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginBottom: theme.spacing(8),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(1), minHeight: (window.innerHeight * 43.7) / 100 },
		minHeight: (window.innerHeight * 53) / 100,
	},
	wrapper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		position: 'relative',
	},
	mainHeader: {
		fontWeight: 'bold',
		fontSize: theme.spacing(6),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: { fontSize: theme.spacing(5), marginTop: theme.spacing(-4) },
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	proofBackground: {
		backgroundColor: '#000000',
		color: 'white',
		fontWeight: 'bold',
	},
	boldText: {
		fontWeight: 'bold',
		fontSize: theme.spacing(2),
	},
});

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			disabled: false,
			isLoggedIn: false,
			userName: '',
			userEmail: '',
			complaints: [],
			complaintsId: [],
			lastTime: {},
			isLoadMore: false,
			hideLoadButton: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	login = (e) => {
		e.preventDefault();

		const loginComponent = this;

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (this.state.email !== '' && this.state.password !== '') {
			if (this.state.email.endsWith('@iitk.ac.in') && re.test(String(this.state.email).toLowerCase())) {
				loginComponent.setState({
					disabled: !loginComponent.state.disabled,
				});

				firebase
					.auth()
					.signInWithEmailAndPassword(this.state.email, this.state.password)
					.then((userCredential) => {
						var user = userCredential.user;

						loginComponent.renderSnackbar('LoggedIn Successfully!', 'success');
						loginComponent.setState(
							{
								isLoggedIn: true,
								disabled: false,
								userEmail: user.email,
							},
							function () {
								loginComponent.fetchData();
							}
						);
					})
					.catch((error) => {
						var errorCode = error.code;
						var errorMessage = error.message;
						console.log(errorCode, errorMessage);
						loginComponent.renderSnackbar(error.message, 'error');
						loginComponent.setState({
							disabled: false,
							isLoggedIn: false,
						});
					});
			} else {
				loginComponent.renderSnackbar('Use your IITK Email Address!', 'error');
			}
		} else {
			loginComponent.renderSnackbar('Please fill all the fields!', 'error');
		}
	};

	fetchData = () => {
		const adminComponent = this;

		if (!adminComponent.state.isLoadMore) {
			firebase
				.firestore()
				.collection('proofs')
				.orderBy('isRead', 'asc')
				.orderBy('timeStamp', 'desc')
				.limit(8)
				.onSnapshot((querySnapshot) => {
					let complaints = [];
					let complaintsId = [];
					let lastTimestamp = {};

					querySnapshot.forEach((doc) => {
						complaints.push(doc.data());
						complaintsId.push(doc.id);
					});

					lastTimestamp = querySnapshot.docs[querySnapshot.docs.length - 1];

					adminComponent.setState({
						complaints: complaints,
						lastTime: lastTimestamp,
						complaintsId: complaintsId,
						isLoadMore: true,
					});
				});
		} else {
			firebase
				.firestore()
				.collection('proofs')
				.orderBy('isRead', 'asc')
				.orderBy('timeStamp', 'desc')
				.startAfter(adminComponent.state.lastTime)
				.limit(8)
				.onSnapshot((querySnapshot) => {
					let complaints = adminComponent.state.complaints;
					let complaintsId = adminComponent.state.complaintsId;
					let lastTimestamp = {};

					querySnapshot.forEach((doc) => {
						complaints.push(doc.data());
						complaintsId.push(doc.id);
					});

					lastTimestamp = querySnapshot.docs[querySnapshot.docs.length - 1];

					if (querySnapshot.empty) {
						adminComponent.setState(
							{
								hideLoadButton: true,
							},
							function () {
								adminComponent.renderSnackbar('All documents have been fetched!', 'info');
							}
						);
					}

					adminComponent.setState({
						complaints: complaints,
						complaintsId: complaintsId,
						lastTime: lastTimestamp,
						isLoadMore: true,
					});
				});
		}
	};

	markReadInDb = (id) => {
		const adminComponent = this;

		firebase
			.firestore()
			.collection('proofs')
			.doc(adminComponent.state.complaintsId[id])
			.update({
				isRead: true,
				isReadBy: adminComponent.state.userEmail,
			})
			.then(() => {
				console.log('Document successfully updated!');
				adminComponent.renderSnackbar('Complaint successfully read!', 'success');
				adminComponent.setState({
					hideLoadButton: false,
				});
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
				adminComponent.renderSnackbar('Error reading complaint!', 'error');
			});
	};

	render() {
		const { classes } = this.props;
		return (
			<>
				<CssBaseline />

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				<div className={classes.appbar}></div>

				{!this.state.isLoggedIn ? (
					<Container maxWidth='xl' className={classes.container}>
						<Typography variant='h4' align='center' className={classes.mainHeader} gutterBottom>
							Login
						</Typography>
						<Container maxWidth='sm'>
							<TextField
								className={classes.inputField}
								name='email'
								value={this.state.email.trim()}
								label='Email-ID'
								onChange={this.onChange}
								placeholder='Enter your IITK email address'
								variant='outlined'
							/>
							<TextField
								className={classes.inputField}
								name='password'
								type='password'
								value={this.state.password}
								label='Password'
								onChange={this.onChange}
								placeholder='Enter your password'
								variant='outlined'
							/>

							<div className={classes.wrapper}>
								<Button onClick={this.login} variant='contained' color='primary' fullWidth disabled={this.state.disabled}>
									Login
								</Button>
								{this.state.disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
							</div>
						</Container>
					</Container>
				) : (
					<Container maxWidth='md' className={classes.container}>
						<Typography variant='h4' align='center' className={classes.mainHeader} gutterBottom>
							Complaints/Proofs
						</Typography>

						{this.state.complaints.length > 0 &&
							this.state.complaints.map((complaint, index) => (
								<Card key={index} variant='outlined' className={classes.root}>
									<Grid container spacing={1}>
										<Grid item xs={12} sm={12}>
											<CardContent>
												<List dense style={{ display: 'flex' }}>
													<Grid container>
														<Grid item xs={12} sm={6}>
															<ListItem>
																<ListItemIcon>
																	<AccountCircleIcon />
																</ListItemIcon>
																<ListItemText primary={complaint.fullname === '' ? 'Anonymous Complaint' : complaint.fullname} />
															</ListItem>

															<ListItem>
																<ListItemIcon>
																	<NumbersIcon />
																</ListItemIcon>
																<ListItemText primary={complaint.rollno === '' ? 'Anonymous Complaint' : complaint.rollno} />
															</ListItem>

															<ListItem>
																<ListItemIcon>
																	<AlternateEmailIcon />
																</ListItemIcon>
																<ListItemText primary={complaint.email === '' ? 'Anonymous Complaint' : complaint.email} />
															</ListItem>

															<ListItem>
																<ListItemIcon>
																	<DescriptionIcon />
																</ListItemIcon>
																<ListItemText
																	primary={complaint.description === '' ? 'Anonymous Complaint' : <span className={classes.boldText}>{complaint.description}</span>}
																/>
															</ListItem>

															<ListItem>
																<ListItemIcon>
																	<AccessTimeIcon />
																</ListItemIcon>
																<ListItemText primary={complaint.timeStamp.toDate().toDateString() + ' | ' + complaint.timeStamp.toDate().toLocaleTimeString()} />
															</ListItem>
														</Grid>

														<Grid item xs={12} sm={6}>
															<Typography variant='h6' align='left' gutterBottom>
																Proofs Url
															</Typography>
															{complaint.proofUrl.map((url, indexs) => (
																<ListItem key={indexs}>
																	<ListItemIcon>
																		<DescriptionIcon />
																	</ListItemIcon>
																	<ListItemText
																		primary={
																			<Link href={url} underline='none' className={classes.boldText} target='_blank'>
																				Link {indexs + 1}
																			</Link>
																		}
																	/>
																</ListItem>
															))}
															{complaint.proofUrl.length === 0 && (
																<ListItem>
																	<ListItemIcon>
																		<DescriptionIcon />
																	</ListItemIcon>
																	<ListItemText primary='No proofs attached.' />
																</ListItem>
															)}
														</Grid>
													</Grid>
												</List>

												{!complaint.isRead ? (
													<Grid item xs={12}>
														<Button variant='contained' color='secondary' onClick={() => this.markReadInDb(index)} fullWidth>
															Mark as read
														</Button>
													</Grid>
												) : (
													<Typography variant='h6' align='left' gutterBottom>
														Document read by : {complaint.isReadBy}
													</Typography>
												)}
											</CardContent>
										</Grid>
									</Grid>
								</Card>
							))}

						{!this.state.hideLoadButton ? (
							<Button variant='contained' color='primary' fullWidth onClick={this.fetchData}>
								Load More
							</Button>
						) : null}
					</Container>
				)}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Admin);
