import React from 'react';
import { AppBar, Container, Grid, Typography, withStyles, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import logo from './../assets/images/logo.png';

const styles = (theme) => ({
	appBar: {
		marginTop: `auto`,
		backgroundColor: '#000',
		// minHeight: (window.innerHeight * 43.7) / 100,
	},
	container: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(3),
	},
	logo: {
		width: theme.spacing(20),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: { width: theme.spacing(7)},
	},
	subHeading: {
		fontWeight: '600',
		marginTop: theme.spacing(2),
	},
});

class Footer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLogin: false,
		};
	}

	componentDidMount() {
		this.setState({
			isLogin: localStorage.getItem('token') === null || localStorage.getItem('token') === '' ? false : true,
		});
	}

	render() {
		const { classes } = this.props;

		const navOptions = [
			['Main Website', 'https://eciitk.com/'],
			['Nomination Portal', 'https://nominations.eciitk.com/'],
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<AppBar position='static' color='primary' className={classes.appBar}>
					<Toolbar>
						<Container maxWidth='md' className={classes.container}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={4}>
									<img src={logo} alt='logo' className={classes.logo} />
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant='h4' align='left' gutterBottom className={classes.subHeading}>
										Useful Links
									</Typography>

									{navOptions.map((option, index) => (
										<Button key={index} href={option[1]} color='inherit' size='large' startIcon={<ArrowForwardIosIcon />} className={classes.navButtons}>
											{option[0]}
										</Button>
									))}
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant='h4' align='left' gutterBottom className={classes.subHeading}>
										Contact Us
									</Typography>
									<Typography variant='subtitle1' align='left' gutterBottom>
										Senate Hall
										<br></br>
										New SAC,
										<br></br>
										OAT IIT Kanpur
										<br></br>
										<span style={{ fontWeight: 'bold' }}>Phone:</span> <a href='tel:+918529434655'>+91 8529434655</a>
										<br></br>
										<span style={{ fontWeight: 'bold' }}>Email:</span> <a href='mailto:ec_sg@iitk.ac.in'>ec_sg@iitk.ac.in</a>
									</Typography>
								</Grid>
							</Grid>
						</Container>
					</Toolbar>
				</AppBar>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(Footer);
