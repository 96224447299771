import React from 'react';
import { AppBar, Toolbar, Typography, withStyles, Link } from '@material-ui/core';
import logo from './../assets/images/logo.png';
const styles = (theme) => ({
	appbar: {
		backgroundColor: '#000',
		marginBottom: theme.spacing(4),
		textAlign: 'right',
	},
	logo: {
		width: theme.spacing(6),
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(0.5),
	},
	heading: {
		flexGrow: 1,
		fontWeight: 'bold',
		fontSize: theme.spacing(4),
		marginTop: theme.spacing(2.2),
		marginBottom: theme.spacing(1.5),
		marginLeft: theme.spacing(2),
		color: 'white',
		[theme.breakpoints.down('xs')]: { fontSize: theme.spacing(2.5) },
	},
});

class Navbar extends React.Component {
	render() {
		const { classes } = this.props;

		return (
			<AppBar position='static' color='default' className={classes.appbar}>
				<Toolbar>
     
						<Link href='/' underline='none' className={classes.link} variant='inherit'>
							<img src={logo} alt='logo' className={classes.logo} />
						</Link>
						<Typography anchor='left'variant='h6' className={classes.heading}>
						
							Anonymous Complaint Portal
						
					</Typography>
				</Toolbar>
			</AppBar>
		);
	}
}

export default withStyles(styles)(Navbar);
