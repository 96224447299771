import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from './Pages/Home';
import Admin from './Pages/Admin';
import Navbar from './Components/Navabar';
import Footer from './Components/Footer';

function App() {
	return (
		<Router>
			<Navbar />
			<main>
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route exact path='/admin' element={<Admin />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
