import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
});

class CustomizedSnackbars extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			snackbarOpen: false,
		};
	}

	handleSnackbarClose = () => {
		if (this.state.snackbarOpen) {
			this.setState({
				snackbarOpen: false,
			});
		} else {
			this.setState({
				snackbarOpen: true,
			});
		}
	};

	render() {
		const { classes, severity, message, open, close } = this.props;

		return (
			<div className={classes.root}>
				<Snackbar open={open} autoHideDuration={3000} onClose={close}>
					<MuiAlert elevation={6} variant='filled' onClose={close} severity={severity}>
						{message}
					</MuiAlert>
				</Snackbar>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(CustomizedSnackbars);
