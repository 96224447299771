import React from 'react';

import { withStyles, CssBaseline, Container, TextField, Button, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SendIcon from '@material-ui/icons/Send';
import { green } from '@material-ui/core/colors';

import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		alignItems: 'center',
	},
	foot: {
		marginBottom: theme.spacing(8),
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	uploadButton: {
		marginTop: theme.spacing(2),
		float: 'left',
		position: 'relative',
	},
	submitButton: {
		marginTop: theme.spacing(2),
		float: 'right',
		width: theme.spacing(20),
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fullname: '',
			rollno: '',
			email: '',
			description: '',
			proof: '',
			proofUrl: [],
			success: false,
			loading: false,
			disableSubmit: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	onFileChange = async (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			this.setState({
				proof: file.name,
				disabled: true,
				loading: true,
			});

			const timeStamp = Date.now();
			const homeComponent = this;

			var uploadTask = firebase
				.storage()
				.ref()
				.child('proofs/' + timeStamp + '_' + file.name)
				.put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				function (snapshot) {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState({ progress_image: progress });

					if (progress === 100) {
						var url;

						setTimeout(() => {
							firebase
								.storage()
								.ref()
								.child('proofs/' + timeStamp + '_' + file.name)
								.getDownloadURL()
								.then(function (downloadURL) {
									url = downloadURL;

									const proofs = homeComponent.state.proofUrl;
									proofs.push(url);
									homeComponent.setState(
										{
											proofUrl: proofs,
										},
										function () {
											homeComponent.setState({
												progress_image: 0,
												disabled: false,
												loading: false,
											});
											homeComponent.renderSnackbar('Proof uploaded successfully!', 'success');
										}
									);
								})
								.catch(function (error) {
									console.log(error);
									homeComponent.setState({
										progress_image: 0,
										disabled: false,
										loading: false,
									});
									homeComponent.renderSnackbar('Error in uploading proof, please try again!', 'error');
								});
						}, 3000);
					}

					console.log('Upload is ' + progress + '% done');
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log('Upload is paused');
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log('Upload is running');
							break;
						default:
							break;
					}
				}.bind(this),
				function (error) {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					console.log(error);

					homeComponent.setState({
						progress_image: 0,
						disabled: false,
						loading: false,
					});
					homeComponent.renderSnackbar('Error in uploading proofs,please try again!', 'error');

					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;
						default:
							break;
					}
				}
			);
		} else {
			this.setState(() => ({ proof: 'image' }));
		}
	};

	onSubmit = () => {
		const homeComponent = this;

		this.setState({ disableSubmit: true });

		if (this.state.email !== '' && !this.state.email.endsWith('@iitk.ac.in')) {
			this.renderSnackbar('Use your IITK Email Address!', 'error');
			return;
		} else if (this.state.description === '') {
			this.renderSnackbar('Please provide a description!', 'error');
			return;
		}

		const submissionValues = {
			fullname: this.state.fullname,
			rollno: this.state.rollno,
			email: this.state.email,
			description: this.state.description,
			proofUrl: this.state.proofUrl,
			timestamp: new Date(),
		};

		const SteinStore = require('stein-js-client');
		const store = new SteinStore('https://api.steinhq.com/v1/storages/6174545cc582292380aeab9f');

		store
			.append('Sheet1', [
				{
					timestamp: submissionValues.timestamp,
					fullname: submissionValues.fullname,
					rollno: submissionValues.rollno,
					email: submissionValues.email,
					description: submissionValues.description,
					proofUrl: submissionValues.proofUrl.toString(),
				},
			])
			.then((res) => {
				firebase
					.firestore()
					.collection('proofs')
					.add({
						fullname: submissionValues.fullname,
						rollno: submissionValues.rollno,
						email: submissionValues.email,
						description: submissionValues.description,
						proofUrl: submissionValues.proofUrl,
						row: res,
						isRead: false,
						isReadBy: '',
						timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.then(() => {
						console.log('Document written');
					})
					.catch((error) => {
						console.error('Error adding document: ', error);
						homeComponent.setState({ disableSubmit: false });
					});

				homeComponent.setState({
					fullname: '',
					rollno: '',
					email: '',
					description: '',
					proofUrl: [],
					disableSubmit: false,
				});

				homeComponent.renderSnackbar('Form submitted successfully!', 'success');
			});
	};

	render() {
		const { classes } = this.props;

		const fields = [
			['Full Name', 'fullname', this.state.fullname, false, 'text', 0, 'enter your full name'],
			['Roll Number', 'rollno', this.state.rollno, false, 'number', 0, 'enter your roll no'],
			['IITK Email ID', 'email', this.state.email, false, 'email', 0, 'enter your iitk email address'],
			['Description of the Complaint/Proof', 'description', this.state.description, true, 'text', 4, 'enter the discription of the complaint/feedback'],
		];

		return (
			<>
				<CssBaseline />

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				<Container maxWidth='md' className={classes.root}>
					<form noValidate autoComplete='on'>
						{fields.map((field, index) => (
							<TextField
								key={index}
								label={field[0]}
								variant='outlined'
								name={field[1]}
								className={classes.inputField}
								value={field[2]}
								minRows={field[5]}
								type={field[4]}
								required={field[3]}
								multiline
								placeholder={field[6]}
								onChange={this.onChange}
							/>
						))}

						<input className={classes.input} id='contained-button-file' multiple type='file' onChange={this.onFileChange} style={{ display: 'none' }} />
						<label htmlFor='contained-button-file'>
							<Button
								variant='contained'
								color='primary'
								component='span'
								disabled={this.state.loading || this.state.disableSubmit}
								className={classes.uploadButton}
								startIcon={<CloudUploadIcon />}
							>
								Upload Proofs
								{this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						</label>

						<Button
							variant='contained'
							color='secondary'
							disabled={this.state.disableSubmit || this.state.loading}
							component='span'
							onClick={this.onSubmit}
							className={classes.submitButton}
							endIcon={<SendIcon />}
						>
							Submit
						</Button>
					</form>
				</Container>
				<Container maxWidth='md' className={classes.foot}>
					<Typography variant='subtitle1' gutterBottom>
						{this.state.proofUrl.length} {this.state.proofUrl.length > 1 ? 'proofs' : 'proof'} uploaded!
					</Typography>

					<Typography variant='body2' style={{ fontWeight: 'bold', fontSize: '15px' }} gutterBottom>
						<br></br>
						Note: This portal is completely anonymous. Add a proper description and upload proofs. Fields of name, roll no and email are optional.
						<br></br>
						You can also upload more than 1 proofs.
					</Typography>
				</Container>
			</>
		);
	}
}

export default withStyles(styles)(Home);
