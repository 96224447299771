import firebase from 'firebase';

const config = {
	apiKey: 'AIzaSyDfoQrXm820QobLvVVtRv0DPY-kO2aybhU',
	authDomain: 'anonymous-complaint-portal.firebaseapp.com',
	projectId: 'anonymous-complaint-portal',
	storageBucket: 'anonymous-complaint-portal.appspot.com',
	messagingSenderId: '1052753088784',
	appId: '1:1052753088784:web:58ac12af6c89806e105595',
	measurementId: 'G-Z5DNY27YLF',
};

firebase.initializeApp(config);
firebase.analytics();

export { firebase };
